export const state = () => ({
  benefitModal: false,
  benefit: {},
  benefitsCredit: ['sale', 'guaranty', 'first-pay', 'docs'],
  benefitsTradeIn: ['sale-trade', 'guaranty', 'value-market-m', 'docs'],
  benefitsBuyout: ['fast-pay', 'value-market-m', 'value-man', 'fast-time'],
  benefitsCar: ['sale', 'guaranty', 'first-pay', 'docs'],
  benefitsCreditMobile: ['docs', 'insurance'],
  benefitsCredit_slider: ['sale-trade', 'credit', 'tires'],
  benefits_autoCredit: [
    'bwb6',
    'bwb2',
    'bwb4',
    'bwb1',
    'bwb3',
    'bwb5'],
  benefits: [
    {
      text: 'Скидка в кредит до 300 000 ₽',
      text_strong: '',
      icon: 'bank',
      description: 'При оформлении сделки по программе автокредитования мы делаем дополнительную скидку на выбранный автомобиль в автосалоне. Скидка суммируется с подарками и другими бонусами.',
      slug: 'sale',
    },
    {
      text: 'Гарантия 1 год',
      text_strong: '',
      icon: 'shield',
      description: 'Мы уверены в своих автомобилях, а потому предоставляем обширную юридическую и техническую гарантию 1 год на любую машину из нашего автоцентра.',
      slug: 'guaranty',
    },
    {
      text: 'Выгода по Trade-In до 200 000 ₽',
      icon: 'bank',
      description: 'При оформлении сделки по программе Trade-In мы делаем дополнительную скидку на выбранный автомобиль в автосалоне. Скидка суммируется с подарками и другими бонусами.',
      slug: 'sale-trade',


      text_small: 'ВЫГОДА ПО TRADE IN',
      text_strong: 'ДО 200 000 ₽'
    },
    {
      text: 'Автокредит',
      text_strong: 'от 4,9%',
      icon: 'bank',
      description: 'Ставка ниже 4,9% по автокредиту является неправдивой (ниже ставки рефинансирования ЦБ РФ) и как правило предлагается недобросовестными сайтами и автосалонами.',
      slug: 'credit',
      text_small: 'СТАВКА ПО КРЕДИТУ',
    },
    {
      text: 'Первый взнос от 0%',
      text_strong: '',
      icon: 'engine',
      description: 'Хотя процент одобрения с первым взносом значительно повышается, наши клиенты ежедневно получают одобрение по автокредиту и без взноса.',
      slug: 'first-pay',
    },
    {
      text: 'Trade-In как',
      text_strong: 'первый взнос',
      icon: 'trade-in',
      description: 'В качестве первоначального взноса Вы можете сдать свой автомобиль по системе Trade-In с дополнительной скидкой до 150 000 ₽.',
      slug: 'first-pay-trade',
    },
    {
      text: 'По 2 документам',
      text_strong: '',
      icon: 'docs',
      description: 'Благодаря сотрудничеству с лучшими банками, мы упростили процедуру оформления автомобиля — вам понадобятся лишь водительское удостоверение и паспорт.',
      slug: 'docs',
    },
    {
      text: 'Зимняя резина',
      text_strong: 'в подарок',
      icon: 'tires',
      description: 'При покупке автомобиля каждый клиент получает комплект второй комплект резины в подарок.',
      slug: 'tires',


      text_small: 'ДАРИМ ПОДАРКИ',

    },
    {
      text: 'ОСАГО',
      text_strong: 'за наш счет',
      icon: 'insurance',
      description: 'При покупке автомобиля в кредит каждый клиент получает ОСАГО в подарок.',
      slug: 'insurance',
    },
    {
      text: 'Рыночная',
      text_strong: 'цена',
      icon: 'ruble',
      description: 'Будьте уверены, что оценка автомобиля будет максимально лояльной, соответствующей рыночным ценам, и Вы получите всю сумму на руки.',
      slug: 'value-market',
    },
    {
      text: 'Рыночная оценка',
      text_strong: '',
      icon: 'ruble',
      description: 'Рыночная оценка проводится профессиональными оценщиками в автосалоне или удобном для вас месте. Совершенно бесплатно для вас.',
      slug: 'value-market-m',
    },
    {
      text: 'Оформление за 30 мин',
      text_strong: '',
      icon: 'engine',
      description: 'За пол часа мы не только оценим ваш автомобиль, но и оформим сделку с выплатой наличных на руки. Таким образом, вы сэкономите уйму времени.',
      slug: 'fast-time',
    },
    {
      text: 'Мгновенная выплата',
      text_strong: '',
      icon: 'credit-card',
      description: 'После оформления документов наши клиенты получают всю сумму наличными на руки. Без задержек в выплате и прочих неудобств.',
      slug: 'fast-pay',
    },
    {
      text: 'Выезд оценщика',
      text_strong: '',
      icon: 'form',
      description: 'Наш автоцентр заключает договор с профессиональным оценщиком, которые моментально выезжает в удобное для вас место и время для оценки автомобиля.',
      slug: 'value-man',
    },
    {
      text: 'Рыночная оценка',
      text_strong: '',
      icon: 'engine',
      description: 'Рыночная оценка проводится профессиональными оценщиками в автосалоне или удобном для вас месте. Совершенно бесплатно для вас.',
      slug: 'value-free',
    },


    {
      text: "Низкая ставка 4,9%",
      icon: 'bwb6',
      slug: 'bwb6',
    },
    {
      text: "Сумма кредита от 50 000 ₽ до 8 000 000 ₽",
      icon: 'bwb2',
      slug: 'bwb2',
    },
    {
      text: "Срок кредита от 2 мес. до 8 лет",
      icon: 'bwb4',
      slug: 'bwb4',
    },
    {
      text: "Первоначальный взнос от 0%",
      icon: 'bwb1',
      slug: 'bwb1',
    },
    {
      text: "Trade-In как первый взнос",
      icon: 'bwb3',
      slug: 'bwb3',
    },
    {
      text: "Комплект резины в подарок",
      icon: 'bwb5',
      slug: 'bwb5',
    },


    {
      text_small: 'Первый взнос ',
      text_strong: 'от 0 ₽',
      slug: 'slider-pay',
    },
    {
      text_small: 'Сумма кредита ',
      text_strong: 'до 8 000 000 ₽',
      slug: 'slider-sale',
    },
    {
      text_small: 'Срок кредита ',
      text_strong: 'от 2 месяцев до 8 лет',
      slug: 'slider-credit',
    },
    {
      text_small: 'Одобрение',
      text_strong: 'по 2-м документам',
      slug: 'slider-approval',
    },
    {
      text_small: 'Досрочное погашение',
      text_strong: 'без штрафов и комиссий',
      slug: 'slider-anticipatorily',
    },


    {
      text_small: 'Trade-In как',
      text_strong: 'первый взнос',
      slug: 'slider-pay-trade',
    },
    {
      text_small: 'Оценка авто',
      text_strong: 'по рыночной стоимости',
      slug: 'slider-sale-trade',
    },

    {
      text_small: 'Оформление',
      text_strong: 'за 30 минут',
      slug: 'slider-value-market-m',
    },


    {
      text_small: 'Оформление ',
      text_strong: 'за 30 мин.',
      slug: 'slider-fast-time',
    },
    {
      text_small: 'Выезд оценщика',
      text_strong: 'бесплатно',
      slug: 'slider-value-man',
    },
    {
      text_small: 'Мгновенная ',
      text_strong: 'выплата',
      slug: 'slider-fast-pay',
    },
    {
      text_small: 'Ставка по ',
      text_strong: 'рассрочке 0%',
      slug: 'slider-installment-pay',
    },


    {
      text_small: 'Автокредит',
      text_strong: 'ставка 4,9%',
      slug: 'slider-card-credit',
    },
    {
      text_small: 'Первый взнос ',
      text_strong: 'от 0 ₽',
      slug: 'slider-card-pay',
    },
    {
      text_small: 'Скидка до ',
      text_strong: '300 000 ₽',
      slug: 'slider-card-sale',
    },
    {
      text_small: 'Гарантия ',
      text_strong: '1 год',
      slug: 'slider-card-garant',
    },

  ],
  benefitsSlider_card: [
    'slider-card-credit',
    'slider-card-pay',
    'slider-card-sale',
    'slider-card-garant',

  ],
  benefitsSlider_credit: [
    "slider-sale",
    "slider-credit",
    "slider-pay",
    'slider-approval',
    'slider-anticipatorily',
  ],
  benefitsSlider_traidin: [
    'slider-sale-trade',
    'slider-pay-trade',
    'slider-value-market-m',
  ],
  benefitsSlider_buyout: [
    'slider-fast-pay',
    'slider-fast-time',
    'slider-value-man'
  ],
  benefitsSlider_installment: [
    'slider-installment-pay',
    'slider-approval',
    'slider-pay-trade',
  ],
  benefitsCarPage: ['Гарантия 2 года', 'Скидка до 300 000 ₽', 'Досрочное погашение без штрафов', 'Оформление за 30 минут', 'Все авто проверены по "Автотеке"']
})
export const getters = {
  benefits(state) {
    return state.benefits
  },
  benefitsCarPage(state) {
    return state.benefitsCarPage
  },
  benefit: (state) => {
    return state.benefit
  },
  benefitsCredit: (state) => {
    return state.benefits.filter((item) => {
      return state.benefitsCredit.indexOf(item.slug) !== -1;
    })
  },
  benefitsCredit_slider: (state) => {
    return state.benefits.filter((item) => {
      return state.benefitsCredit_slider.indexOf(item.slug) !== -1;
    })
  },
  benefitsTradeIn: (state) => {
    return state.benefits.filter((item) => {
      return state.benefitsTradeIn.indexOf(item.slug) !== -1;
    })
  },
  benefitsBuyout: (state) => {
    return state.benefits.filter((item) => {
      return state.benefitsBuyout.indexOf(item.slug) !== -1;
    })
  },
  benefitsCar: (state) => {
    return state.benefits.filter((item) => {
      return state.benefitsCar.indexOf(item.slug) !== -1;
    })
  },
  benefitsCreditMobile: (state) => {
    return state.benefits.filter((item) => {
      return state.benefitsCreditMobile.indexOf(item.slug) !== -1;
    })
  },
  benefitModal: (state) => {
    return state.benefitModal
  },


  benefits_autoCredit: (state) => {
    return state.benefits.filter((item) => {
      return state.benefits_autoCredit.indexOf(item.slug) !== -1;
    })
  },

  benefitsSlider_card: (state) => {
    return state.benefits.filter((item) => {
      return state.benefitsSlider_card.indexOf(item.slug) !== -1;
    })
  },


  benefitsSlider_credit: (state) => {
    return state.benefits.filter((item) => {
      return state.benefitsSlider_credit.indexOf(item.slug) !== -1;
    })
  },
  benefitsSlider_traidin: (state) => {
    return state.benefits.filter((item) => {
      return state.benefitsSlider_traidin.indexOf(item.slug) !== -1;
    })
  },
  benefitsSlider_buyout: (state) => {
    return state.benefits.filter((item) => {
      return state.benefitsSlider_buyout.indexOf(item.slug) !== -1;
    })
  },


  benefitsSlider_installment: (state) => {
    return state.benefits.filter((item) => {
      return state.benefitsSlider_installment.indexOf(item.slug) !== -1;
    }).sort((l, r) => state.benefitsSlider_installment.indexOf(l.slug) - state.benefitsSlider_installment.indexOf(r.slug))
  },


}
export const actions = {
  async openBenefit({commit}, payload) {
    await commit('SET_BENEFIT', payload)
    await commit('SET_BENEFIT_MODAL', true)
  },
  async closeBenefit({commit}) {
    await commit('SET_BENEFIT_MODAL', false)
    await commit('SET_BENEFIT', {})
  },

}
export const mutations = {
  SET_BENEFIT_MODAL(state, data) {
    state.benefitModal = data
  },
  SET_BENEFIT(state, data) {
    state.benefit = data
  },
}

