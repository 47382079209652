
export default {
	props: {
		classes: String,
		small: String,
		smallWebp: String,
		big: String,
		bigWebp: String,
		lazy: Boolean,
    smallWebpMobile: String,
    bigWebpMobile: String,
	}
}
