export const state = () => ({
    isNY: false
})
export const getters = {
    isNY(state) {
        return state.isNY
    },
}
export const mutations = {
    SET_IS_NY(state, data) {
        state.isNY = data
    },
}