import folders from '~/apollo/queries/folder/folders.gql'

export const state = () => ({
  models: [],
  offers: [],
  currentMark: null,
  currentModel: null,
  currentCar: null,
  error: ''
})
export const getters = {
  currentMark: (state) => {
    return state.currentMark
  },
  currentModel: (state) => {
    return state.currentModel
  },
  currentCar: (state) => {
    return state.currentCar
  },
  models: (state) => {
    return state.models
  },
  generations: (state) => {
    return state.generations
  },
  error: (state) => {
    return state.error
  }
}
export const actions = {
  async getModels({commit, state, rootState}, payload) {
    let variables = {
      site_id: rootState.site_id,
      mark_slug: state.currentMark.slug,
      category: 'avto-s-probegom'
    }
    let client = this.app.apolloProvider.defaultClient
    let response = await client.query(
      {
        query: folders,
        variables: Object.assign(variables)
      })
    commit('SET_MODELS', response.data.folders)
  },
  async clearData({commit}) {
    await commit('SET_CURRENT_MARK', null)
    await commit('SET_CURRENT_MODEL', null)
    await commit('SET_CURRENT_CAR', null)
  },
  async chooseMark({commit, dispatch}, payload) {
    await commit('SET_CURRENT_MODEL', null);
    await commit('SET_MODELS', null);
    await commit('SET_CURRENT_MARK', payload);
    await dispatch('getModels', payload);
  },
  async chooseModel({commit, dispatch}, payload) {
    await commit('SET_CURRENT_MODEL', payload);
  },
  async setError({commit, dispatch}, payload) {
    await commit('SET_ERROR', payload);
  },
}
export const mutations = {
  SET_CURRENT_MARK(state, data) {
    state.currentMark = data
  },
  SET_MODELS(state, data) {
    state.models = data
  },
  SET_CURRENT_MODEL(state, data) {
    state.currentModel = data
  },
  SET_CURRENT_CAR(state, data) {
    state.currentCar = data
  },
  SET_ERROR(state, data) {
    state.error = data
  },
}
