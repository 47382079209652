export const CollectionsMain = () => import('../../components/Collections/main.vue' /* webpackChunkName: "components/collections-main" */).then(c => wrapFunctional(c.default || c))
export const CollectionsMarks = () => import('../../components/Collections/marks.vue' /* webpackChunkName: "components/collections-marks" */).then(c => wrapFunctional(c.default || c))
export const CollectionsOffersMain = () => import('../../components/Collections/offers-main.vue' /* webpackChunkName: "components/collections-offers-main" */).then(c => wrapFunctional(c.default || c))
export const Difference = () => import('../../components/Difference/index.vue' /* webpackChunkName: "components/difference" */).then(c => wrapFunctional(c.default || c))
export const Banks = () => import('../../components/Banks/index.vue' /* webpackChunkName: "components/banks" */).then(c => wrapFunctional(c.default || c))
export const BanksPage = () => import('../../components/Banks/page.vue' /* webpackChunkName: "components/banks-page" */).then(c => wrapFunctional(c.default || c))
export const ConditionsFreeSelections = () => import('../../components/Conditions/free-selections.vue' /* webpackChunkName: "components/conditions-free-selections" */).then(c => wrapFunctional(c.default || c))
export const Conditions = () => import('../../components/Conditions/index.vue' /* webpackChunkName: "components/conditions" */).then(c => wrapFunctional(c.default || c))
export const FooterInf = () => import('../../components/Footer/footer-inf.vue' /* webpackChunkName: "components/footer-inf" */).then(c => wrapFunctional(c.default || c))
export const FooterLegal = () => import('../../components/Footer/footer-legal.vue' /* webpackChunkName: "components/footer-legal" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer/footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const HeaderLogo = () => import('../../components/Header/header-logo.vue' /* webpackChunkName: "components/header-logo" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header/header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const InputsCheckOffers = () => import('../../components/Inputs/CheckOffers.vue' /* webpackChunkName: "components/inputs-check-offers" */).then(c => wrapFunctional(c.default || c))
export const InputsExpander = () => import('../../components/Inputs/Expander.vue' /* webpackChunkName: "components/inputs-expander" */).then(c => wrapFunctional(c.default || c))
export const InputsInput = () => import('../../components/Inputs/Input.vue' /* webpackChunkName: "components/inputs-input" */).then(c => wrapFunctional(c.default || c))
export const InputsSelect = () => import('../../components/Inputs/Select.vue' /* webpackChunkName: "components/inputs-select" */).then(c => wrapFunctional(c.default || c))
export const InputsNewSelect = () => import('../../components/Inputs/newSelect.vue' /* webpackChunkName: "components/inputs-new-select" */).then(c => wrapFunctional(c.default || c))
export const MiniCardFormInfoBody = () => import('../../components/MiniCard/form-info-body.vue' /* webpackChunkName: "components/mini-card-form-info-body" */).then(c => wrapFunctional(c.default || c))
export const MiniCardFormNew = () => import('../../components/MiniCard/form-new.vue' /* webpackChunkName: "components/mini-card-form-new" */).then(c => wrapFunctional(c.default || c))
export const MiniCardNewFormSlider = () => import('../../components/MiniCard/new-form-slider.vue' /* webpackChunkName: "components/mini-card-new-form-slider" */).then(c => wrapFunctional(c.default || c))
export const Sliders = () => import('../../components/Sliders/Index.vue' /* webpackChunkName: "components/sliders" */).then(c => wrapFunctional(c.default || c))
export const About = () => import('../../components/about/about.vue' /* webpackChunkName: "components/about" */).then(c => wrapFunctional(c.default || c))
export const AboutText = () => import('../../components/about/text.vue' /* webpackChunkName: "components/about-text" */).then(c => wrapFunctional(c.default || c))
export const AdvantagesMain = () => import('../../components/advantages/advantages-main.vue' /* webpackChunkName: "components/advantages-main" */).then(c => wrapFunctional(c.default || c))
export const Advantages = () => import('../../components/advantages/advantages.vue' /* webpackChunkName: "components/advantages" */).then(c => wrapFunctional(c.default || c))
export const ApplicationBanks = () => import('../../components/application/application-banks.vue' /* webpackChunkName: "components/application-banks" */).then(c => wrapFunctional(c.default || c))
export const ApplicationBuyout = () => import('../../components/application/application-buyout.vue' /* webpackChunkName: "components/application-buyout" */).then(c => wrapFunctional(c.default || c))
export const ApplicationCredit = () => import('../../components/application/application-credit.vue' /* webpackChunkName: "components/application-credit" */).then(c => wrapFunctional(c.default || c))
export const ApplicationFreeSelection = () => import('../../components/application/application-free-selection.vue' /* webpackChunkName: "components/application-free-selection" */).then(c => wrapFunctional(c.default || c))
export const ApplicationInstallment = () => import('../../components/application/application-installment.vue' /* webpackChunkName: "components/application-installment" */).then(c => wrapFunctional(c.default || c))
export const ApplicationTradeIn = () => import('../../components/application/application-trade-in.vue' /* webpackChunkName: "components/application-trade-in" */).then(c => wrapFunctional(c.default || c))
export const BenefitsModal = () => import('../../components/benefits/benefits-modal.vue' /* webpackChunkName: "components/benefits-modal" */).then(c => wrapFunctional(c.default || c))
export const BenefitsSlider = () => import('../../components/benefits/benefits-slider.vue' /* webpackChunkName: "components/benefits-slider" */).then(c => wrapFunctional(c.default || c))
export const BenefitsWithBorder = () => import('../../components/benefits/benefits-withBorder.vue' /* webpackChunkName: "components/benefits-with-border" */).then(c => wrapFunctional(c.default || c))
export const Benefits = () => import('../../components/benefits/benefits.vue' /* webpackChunkName: "components/benefits" */).then(c => wrapFunctional(c.default || c))
export const ButtonFavorite = () => import('../../components/button/button-favorite.vue' /* webpackChunkName: "components/button-favorite" */).then(c => wrapFunctional(c.default || c))
export const ButtonFilter = () => import('../../components/button/button-filter.vue' /* webpackChunkName: "components/button-filter" */).then(c => wrapFunctional(c.default || c))
export const ButtonTypical = () => import('../../components/button/button-typical.vue' /* webpackChunkName: "components/button-typical" */).then(c => wrapFunctional(c.default || c))
export const ButtonViewL = () => import('../../components/button/button-view-l.vue' /* webpackChunkName: "components/button-view-l" */).then(c => wrapFunctional(c.default || c))
export const ButtonViewS = () => import('../../components/button/button-view-s.vue' /* webpackChunkName: "components/button-view-s" */).then(c => wrapFunctional(c.default || c))
export const CarSliderForm = () => import('../../components/car/car-slider-form.vue' /* webpackChunkName: "components/car-slider-form" */).then(c => wrapFunctional(c.default || c))
export const Car2Desktop = () => import('../../components/car/car2-desktop.vue' /* webpackChunkName: "components/car2-desktop" */).then(c => wrapFunctional(c.default || c))
export const CarPageBenefitsCar = () => import('../../components/car_page/benefits-car.vue' /* webpackChunkName: "components/car-page-benefits-car" */).then(c => wrapFunctional(c.default || c))
export const CarBuyUpper = () => import('../../components/car_page/car-buy-upper.vue' /* webpackChunkName: "components/car-buy-upper" */).then(c => wrapFunctional(c.default || c))
export const CarInfo = () => import('../../components/car_page/car-info.vue' /* webpackChunkName: "components/car-info" */).then(c => wrapFunctional(c.default || c))
export const CarTech = () => import('../../components/car_page/car-tech.vue' /* webpackChunkName: "components/car-tech" */).then(c => wrapFunctional(c.default || c))
export const CarPageCard = () => import('../../components/car_page/card.vue' /* webpackChunkName: "components/car-page-card" */).then(c => wrapFunctional(c.default || c))
export const CarPageSlider = () => import('../../components/car_page/slider.vue' /* webpackChunkName: "components/car-page-slider" */).then(c => wrapFunctional(c.default || c))
export const CatalogFavorites = () => import('../../components/catalog/catalog-favorites.vue' /* webpackChunkName: "components/catalog-favorites" */).then(c => wrapFunctional(c.default || c))
export const CatalogFolders = () => import('../../components/catalog/catalog-folders.vue' /* webpackChunkName: "components/catalog-folders" */).then(c => wrapFunctional(c.default || c))
export const CatalogGenerations = () => import('../../components/catalog/catalog-generations.vue' /* webpackChunkName: "components/catalog-generations" */).then(c => wrapFunctional(c.default || c))
export const CatalogIndexSwiper = () => import('../../components/catalog/catalog-index-swiper.vue' /* webpackChunkName: "components/catalog-index-swiper" */).then(c => wrapFunctional(c.default || c))
export const CatalogMarks = () => import('../../components/catalog/catalog-marks.vue' /* webpackChunkName: "components/catalog-marks" */).then(c => wrapFunctional(c.default || c))
export const CatalogServices = () => import('../../components/catalog/catalog-services.vue' /* webpackChunkName: "components/catalog-services" */).then(c => wrapFunctional(c.default || c))
export const CatalogSubFilters = () => import('../../components/catalog/catalog-sub-filters.vue' /* webpackChunkName: "components/catalog-sub-filters" */).then(c => wrapFunctional(c.default || c))
export const CatalogUsed = () => import('../../components/catalog/catalog-used.vue' /* webpackChunkName: "components/catalog-used" */).then(c => wrapFunctional(c.default || c))
export const CheckboxFilter = () => import('../../components/checkbox/checkbox-filter.vue' /* webpackChunkName: "components/checkbox-filter" */).then(c => wrapFunctional(c.default || c))
export const CheckboxForm = () => import('../../components/checkbox/checkbox-form.vue' /* webpackChunkName: "components/checkbox-form" */).then(c => wrapFunctional(c.default || c))
export const ContactsMain = () => import('../../components/contacts/contacts-main.vue' /* webpackChunkName: "components/contacts-main" */).then(c => wrapFunctional(c.default || c))
export const ContactsSlider = () => import('../../components/contacts/contacts-slider.vue' /* webpackChunkName: "components/contacts-slider" */).then(c => wrapFunctional(c.default || c))
export const Counter = () => import('../../components/counter/index.vue' /* webpackChunkName: "components/counter" */).then(c => wrapFunctional(c.default || c))
export const CrumbsItem = () => import('../../components/crumbs/crumbs-item.vue' /* webpackChunkName: "components/crumbs-item" */).then(c => wrapFunctional(c.default || c))
export const Crumbs = () => import('../../components/crumbs/crumbs.vue' /* webpackChunkName: "components/crumbs" */).then(c => wrapFunctional(c.default || c))
export const FilterListIndexPage = () => import('../../components/filter/filter-list-indexPage.vue' /* webpackChunkName: "components/filter-list-index-page" */).then(c => wrapFunctional(c.default || c))
export const FilterList = () => import('../../components/filter/filter-list.vue' /* webpackChunkName: "components/filter-list" */).then(c => wrapFunctional(c.default || c))
export const FilterSort = () => import('../../components/filter/filter-sort.vue' /* webpackChunkName: "components/filter-sort" */).then(c => wrapFunctional(c.default || c))
export const FeaturedBank = () => import('../../components/featured/featured-bank.vue' /* webpackChunkName: "components/featured-bank" */).then(c => wrapFunctional(c.default || c))
export const FeaturedBanks = () => import('../../components/featured/featured-banks.vue' /* webpackChunkName: "components/featured-banks" */).then(c => wrapFunctional(c.default || c))
export const FeaturedCarsPopular = () => import('../../components/featured/featured-cars-popular.vue' /* webpackChunkName: "components/featured-cars-popular" */).then(c => wrapFunctional(c.default || c))
export const FormExtendedCallback = () => import('../../components/form/extended-callback.vue' /* webpackChunkName: "components/form-extended-callback" */).then(c => wrapFunctional(c.default || c))
export const FormBuyout2 = () => import('../../components/form/form-buyout2.vue' /* webpackChunkName: "components/form-buyout2" */).then(c => wrapFunctional(c.default || c))
export const FormCallbackPage = () => import('../../components/form/form-callback-page.vue' /* webpackChunkName: "components/form-callback-page" */).then(c => wrapFunctional(c.default || c))
export const FormCallbackPhone = () => import('../../components/form/form-callback-phone.vue' /* webpackChunkName: "components/form-callback-phone" */).then(c => wrapFunctional(c.default || c))
export const FormCallback = () => import('../../components/form/form-callback.vue' /* webpackChunkName: "components/form-callback" */).then(c => wrapFunctional(c.default || c))
export const FormCarSimulation = () => import('../../components/form/form-car-simulation.vue' /* webpackChunkName: "components/form-car-simulation" */).then(c => wrapFunctional(c.default || c))
export const FormChooseCar = () => import('../../components/form/form-choose-car.vue' /* webpackChunkName: "components/form-choose-car" */).then(c => wrapFunctional(c.default || c))
export const FormCreditMini = () => import('../../components/form/form-credit-mini.vue' /* webpackChunkName: "components/form-credit-mini" */).then(c => wrapFunctional(c.default || c))
export const FormCreditModal = () => import('../../components/form/form-credit-modal.vue' /* webpackChunkName: "components/form-credit-modal" */).then(c => wrapFunctional(c.default || c))
export const FormCredit2Desktop = () => import('../../components/form/form-credit2-desktop.vue' /* webpackChunkName: "components/form-credit2-desktop" */).then(c => wrapFunctional(c.default || c))
export const FormCredit2 = () => import('../../components/form/form-credit2.vue' /* webpackChunkName: "components/form-credit2" */).then(c => wrapFunctional(c.default || c))
export const FormFreeSelection = () => import('../../components/form/form-free-selection.vue' /* webpackChunkName: "components/form-free-selection" */).then(c => wrapFunctional(c.default || c))
export const FormInstallment2Desktop = () => import('../../components/form/form-installment2-desktop.vue' /* webpackChunkName: "components/form-installment2-desktop" */).then(c => wrapFunctional(c.default || c))
export const FormInstallment2 = () => import('../../components/form/form-installment2.vue' /* webpackChunkName: "components/form-installment2" */).then(c => wrapFunctional(c.default || c))
export const FormSelectionCallback = () => import('../../components/form/form-selection-callback.vue' /* webpackChunkName: "components/form-selection-callback" */).then(c => wrapFunctional(c.default || c))
export const FormTestDriveFull = () => import('../../components/form/form-test-drive-full.vue' /* webpackChunkName: "components/form-test-drive-full" */).then(c => wrapFunctional(c.default || c))
export const FormTestDrive = () => import('../../components/form/form-test-drive.vue' /* webpackChunkName: "components/form-test-drive" */).then(c => wrapFunctional(c.default || c))
export const FormTradeIn = () => import('../../components/form/form-trade-in.vue' /* webpackChunkName: "components/form-trade-in" */).then(c => wrapFunctional(c.default || c))
export const FormTradeIn2 = () => import('../../components/form/form-trade-in2.vue' /* webpackChunkName: "components/form-trade-in2" */).then(c => wrapFunctional(c.default || c))
export const FormSelect = () => import('../../components/form/select.vue' /* webpackChunkName: "components/form-select" */).then(c => wrapFunctional(c.default || c))
export const HeadingH1 = () => import('../../components/heading/heading-h1.vue' /* webpackChunkName: "components/heading-h1" */).then(c => wrapFunctional(c.default || c))
export const MakesGenerations = () => import('../../components/makes/makes-generations.vue' /* webpackChunkName: "components/makes-generations" */).then(c => wrapFunctional(c.default || c))
export const MakesItemModal = () => import('../../components/makes/makes-item-modal.vue' /* webpackChunkName: "components/makes-item-modal" */).then(c => wrapFunctional(c.default || c))
export const MakesItemPopular = () => import('../../components/makes/makes-item-popular.vue' /* webpackChunkName: "components/makes-item-popular" */).then(c => wrapFunctional(c.default || c))
export const MakesItem = () => import('../../components/makes/makes-item.vue' /* webpackChunkName: "components/makes-item" */).then(c => wrapFunctional(c.default || c))
export const MakesLettersItem = () => import('../../components/makes/makes-letters-item.vue' /* webpackChunkName: "components/makes-letters-item" */).then(c => wrapFunctional(c.default || c))
export const MakesLetters = () => import('../../components/makes/makes-letters.vue' /* webpackChunkName: "components/makes-letters" */).then(c => wrapFunctional(c.default || c))
export const MakesModalDesktop = () => import('../../components/makes/makes-modal-desktop.vue' /* webpackChunkName: "components/makes-modal-desktop" */).then(c => wrapFunctional(c.default || c))
export const MakesModels = () => import('../../components/makes/makes-models.vue' /* webpackChunkName: "components/makes-models" */).then(c => wrapFunctional(c.default || c))
export const MakesUpper = () => import('../../components/makes/makes-upper.vue' /* webpackChunkName: "components/makes-upper" */).then(c => wrapFunctional(c.default || c))
export const Makes = () => import('../../components/makes/makes.vue' /* webpackChunkName: "components/makes" */).then(c => wrapFunctional(c.default || c))
export const MapContactsMap = () => import('../../components/map/contacts-map.vue' /* webpackChunkName: "components/map-contacts-map" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../components/map/index.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/pagination/pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const PictureComponent = () => import('../../components/picture/picture-component.vue' /* webpackChunkName: "components/picture-component" */).then(c => wrapFunctional(c.default || c))
export const ModalAdvantages = () => import('../../components/modal/modal-advantages.vue' /* webpackChunkName: "components/modal-advantages" */).then(c => wrapFunctional(c.default || c))
export const ModalAutoteka = () => import('../../components/modal/modal-autoteka.vue' /* webpackChunkName: "components/modal-autoteka" */).then(c => wrapFunctional(c.default || c))
export const ModalBenefits = () => import('../../components/modal/modal-benefits.vue' /* webpackChunkName: "components/modal-benefits" */).then(c => wrapFunctional(c.default || c))
export const ModalCallbackFixed = () => import('../../components/modal/modal-callback-fixed.vue' /* webpackChunkName: "components/modal-callback-fixed" */).then(c => wrapFunctional(c.default || c))
export const ModalCallbackMain = () => import('../../components/modal/modal-callback-main.vue' /* webpackChunkName: "components/modal-callback-main" */).then(c => wrapFunctional(c.default || c))
export const ModalCallback = () => import('../../components/modal/modal-callback.vue' /* webpackChunkName: "components/modal-callback" */).then(c => wrapFunctional(c.default || c))
export const ModalComplect = () => import('../../components/modal/modal-complect.vue' /* webpackChunkName: "components/modal-complect" */).then(c => wrapFunctional(c.default || c))
export const ModalCondition = () => import('../../components/modal/modal-condition.vue' /* webpackChunkName: "components/modal-condition" */).then(c => wrapFunctional(c.default || c))
export const ModalCookieMain = () => import('../../components/modal/modal-cookie-main.vue' /* webpackChunkName: "components/modal-cookie-main" */).then(c => wrapFunctional(c.default || c))
export const ModalCredit = () => import('../../components/modal/modal-credit.vue' /* webpackChunkName: "components/modal-credit" */).then(c => wrapFunctional(c.default || c))
export const ModalMenu = () => import('../../components/modal/modal-menu.vue' /* webpackChunkName: "components/modal-menu" */).then(c => wrapFunctional(c.default || c))
export const ModalProgram = () => import('../../components/modal/modal-program.vue' /* webpackChunkName: "components/modal-program" */).then(c => wrapFunctional(c.default || c))
export const ModalRating = () => import('../../components/modal/modal-rating.vue' /* webpackChunkName: "components/modal-rating" */).then(c => wrapFunctional(c.default || c))
export const ModalTradeIn = () => import('../../components/modal/modal-tradeIn.vue' /* webpackChunkName: "components/modal-trade-in" */).then(c => wrapFunctional(c.default || c))
export const ModalWrap = () => import('../../components/modal/modal-wrap.vue' /* webpackChunkName: "components/modal-wrap" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/modal/modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const Privacy = () => import('../../components/privacy/privacy.vue' /* webpackChunkName: "components/privacy" */).then(c => wrapFunctional(c.default || c))
export const Programs = () => import('../../components/programs/index.vue' /* webpackChunkName: "components/programs" */).then(c => wrapFunctional(c.default || c))
export const RangePayment = () => import('../../components/range/range-payment.vue' /* webpackChunkName: "components/range-payment" */).then(c => wrapFunctional(c.default || c))
export const RangePeriod = () => import('../../components/range/range-period.vue' /* webpackChunkName: "components/range-period" */).then(c => wrapFunctional(c.default || c))
export const RangePrice2 = () => import('../../components/range/range-price2.vue' /* webpackChunkName: "components/range-price2" */).then(c => wrapFunctional(c.default || c))
export const RangeSlider = () => import('../../components/range/range-slider.vue' /* webpackChunkName: "components/range-slider" */).then(c => wrapFunctional(c.default || c))
export const RangeSlider2 = () => import('../../components/range/range-slider2.vue' /* webpackChunkName: "components/range-slider2" */).then(c => wrapFunctional(c.default || c))
export const RangeYear2 = () => import('../../components/range/range-year2.vue' /* webpackChunkName: "components/range-year2" */).then(c => wrapFunctional(c.default || c))
export const Reviews = () => import('../../components/reviews/reviews.vue' /* webpackChunkName: "components/reviews" */).then(c => wrapFunctional(c.default || c))
export const Rating = () => import('../../components/rating/rating.vue' /* webpackChunkName: "components/rating" */).then(c => wrapFunctional(c.default || c))
export const Selections = () => import('../../components/selections/index.vue' /* webpackChunkName: "components/selections" */).then(c => wrapFunctional(c.default || c))
export const SeoTextAbout = () => import('../../components/seo-text/seo-text-about.vue' /* webpackChunkName: "components/seo-text-about" */).then(c => wrapFunctional(c.default || c))
export const SeoTextUsed = () => import('../../components/seo-text/seo-text-used.vue' /* webpackChunkName: "components/seo-text-used" */).then(c => wrapFunctional(c.default || c))
export const SkeletonCar = () => import('../../components/skeleton/skeleton-car.vue' /* webpackChunkName: "components/skeleton-car" */).then(c => wrapFunctional(c.default || c))
export const SliderDealer = () => import('../../components/slider-dealer/slider-dealer.vue' /* webpackChunkName: "components/slider-dealer" */).then(c => wrapFunctional(c.default || c))
export const SliderIndexMainNew = () => import('../../components/slider-index/main-new.vue' /* webpackChunkName: "components/slider-index-main-new" */).then(c => wrapFunctional(c.default || c))
export const SliderIndexSlide = () => import('../../components/slider-index/slider-index-slide.vue' /* webpackChunkName: "components/slider-index-slide" */).then(c => wrapFunctional(c.default || c))
export const SliderIndexSlide2 = () => import('../../components/slider-index/slider-index-slide2.vue' /* webpackChunkName: "components/slider-index-slide2" */).then(c => wrapFunctional(c.default || c))
export const TabsItem = () => import('../../components/tabs/tabs-item.vue' /* webpackChunkName: "components/tabs-item" */).then(c => wrapFunctional(c.default || c))
export const TextFooter = () => import('../../components/text/text-footer.vue' /* webpackChunkName: "components/text-footer" */).then(c => wrapFunctional(c.default || c))
export const Thanks = () => import('../../components/thanks/thanks.vue' /* webpackChunkName: "components/thanks" */).then(c => wrapFunctional(c.default || c))
export const TippyQuestion = () => import('../../components/tippy/tippy-question.vue' /* webpackChunkName: "components/tippy-question" */).then(c => wrapFunctional(c.default || c))
export const CatalogListCarNew = () => import('../../components/catalog/catalog-list/catalog-list-car-new.vue' /* webpackChunkName: "components/catalog-list-car-new" */).then(c => wrapFunctional(c.default || c))
export const CatalogListCar = () => import('../../components/catalog/catalog-list/catalog-list-car.vue' /* webpackChunkName: "components/catalog-list-car" */).then(c => wrapFunctional(c.default || c))
export const CatalogListColumn = () => import('../../components/catalog/catalog-list/catalog-list-column.vue' /* webpackChunkName: "components/catalog-list-column" */).then(c => wrapFunctional(c.default || c))
export const CatalogListIndex = () => import('../../components/catalog/catalog-list/catalog-list-index.vue' /* webpackChunkName: "components/catalog-list-index" */).then(c => wrapFunctional(c.default || c))
export const CatalogListUsedDesktop = () => import('../../components/catalog/catalog-list/catalog-list-used-desktop.vue' /* webpackChunkName: "components/catalog-list-used-desktop" */).then(c => wrapFunctional(c.default || c))
export const CatalogListUsedMobile = () => import('../../components/catalog/catalog-list/catalog-list-used-mobile.vue' /* webpackChunkName: "components/catalog-list-used-mobile" */).then(c => wrapFunctional(c.default || c))
export const CatalogColumnMinicard = () => import('../../components/catalog/catalog-item/catalog-column-minicard.vue' /* webpackChunkName: "components/catalog-column-minicard" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemGap = () => import('../../components/catalog/catalog-item/catalog-item-gap.vue' /* webpackChunkName: "components/catalog-item-gap" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemLargeDesktop = () => import('../../components/catalog/catalog-item/catalog-item-large-desktop.vue' /* webpackChunkName: "components/catalog-item-large-desktop" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemLargeMobileForm = () => import('../../components/catalog/catalog-item/catalog-item-large-mobile-form.vue' /* webpackChunkName: "components/catalog-item-large-mobile-form" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemLargeMobile = () => import('../../components/catalog/catalog-item/catalog-item-large-mobile.vue' /* webpackChunkName: "components/catalog-item-large-mobile" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemMinicard = () => import('../../components/catalog/catalog-item/catalog-item-minicard.vue' /* webpackChunkName: "components/catalog-item-minicard" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemSmallDesktop = () => import('../../components/catalog/catalog-item/catalog-item-small-desktop.vue' /* webpackChunkName: "components/catalog-item-small-desktop" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemSmallDesktop2 = () => import('../../components/catalog/catalog-item/catalog-item-small-desktop2.vue' /* webpackChunkName: "components/catalog-item-small-desktop2" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemSmallMobile = () => import('../../components/catalog/catalog-item/catalog-item-small-mobile.vue' /* webpackChunkName: "components/catalog-item-small-mobile" */).then(c => wrapFunctional(c.default || c))
export const FormCreditCalculator = () => import('../../components/form/form-components/form-credit-calculator.vue' /* webpackChunkName: "components/form-credit-calculator" */).then(c => wrapFunctional(c.default || c))
export const FormTestDriveChoose = () => import('../../components/form/test-drive/choose.vue' /* webpackChunkName: "components/form-test-drive-choose" */).then(c => wrapFunctional(c.default || c))
export const ModalChooseCars = () => import('../../components/modal/modal-choose/modal-choose-cars.vue' /* webpackChunkName: "components/modal-choose-cars" */).then(c => wrapFunctional(c.default || c))
export const ModalChooseGeneration = () => import('../../components/modal/modal-choose/modal-choose-generation.vue' /* webpackChunkName: "components/modal-choose-generation" */).then(c => wrapFunctional(c.default || c))
export const ModalChooseMark = () => import('../../components/modal/modal-choose/modal-choose-mark.vue' /* webpackChunkName: "components/modal-choose-mark" */).then(c => wrapFunctional(c.default || c))
export const ModalChooseModel = () => import('../../components/modal/modal-choose/modal-choose-model.vue' /* webpackChunkName: "components/modal-choose-model" */).then(c => wrapFunctional(c.default || c))
export const ModalChoose = () => import('../../components/modal/modal-choose/modal-choose.vue' /* webpackChunkName: "components/modal-choose" */).then(c => wrapFunctional(c.default || c))
export const ModalFilterMobile = () => import('../../components/modal/modal-filter/modal-filter-mobile.vue' /* webpackChunkName: "components/modal-filter-mobile" */).then(c => wrapFunctional(c.default || c))
export const SkeletonCardSmall = () => import('../../components/skeleton/skeleton-card/skeleton-card-small.vue' /* webpackChunkName: "components/skeleton-card-small" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemButtons = () => import('../../components/catalog/catalog-item/catalog-item-components/catalog-item-buttons.vue' /* webpackChunkName: "components/catalog-item-buttons" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemHoverSliderMobile = () => import('../../components/catalog/catalog-item/catalog-item-components/catalog-item-hover-slider-mobile.vue' /* webpackChunkName: "components/catalog-item-hover-slider-mobile" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemHoverSliderSelectedCar = () => import('../../components/catalog/catalog-item/catalog-item-components/catalog-item-hover-slider-selectedCar.vue' /* webpackChunkName: "components/catalog-item-hover-slider-selected-car" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemHoverSlider = () => import('../../components/catalog/catalog-item/catalog-item-components/catalog-item-hover-slider.vue' /* webpackChunkName: "components/catalog-item-hover-slider" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemHoverSlider2 = () => import('../../components/catalog/catalog-item/catalog-item-components/catalog-item-hover-slider2.vue' /* webpackChunkName: "components/catalog-item-hover-slider2" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemImg = () => import('../../components/catalog/catalog-item/catalog-item-components/catalog-item-img.vue' /* webpackChunkName: "components/catalog-item-img" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemInfoBody = () => import('../../components/catalog/catalog-item/catalog-item-components/catalog-item-info-body.vue' /* webpackChunkName: "components/catalog-item-info-body" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemPrice = () => import('../../components/catalog/catalog-item/catalog-item-components/catalog-item-price.vue' /* webpackChunkName: "components/catalog-item-price" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemService = () => import('../../components/catalog/catalog-item/catalog-item-components/catalog-item-service.vue' /* webpackChunkName: "components/catalog-item-service" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemSwiper = () => import('../../components/catalog/catalog-item/catalog-item-components/catalog-item-swiper.vue' /* webpackChunkName: "components/catalog-item-swiper" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemTechList = () => import('../../components/catalog/catalog-item/catalog-item-components/catalog-item-tech-list.vue' /* webpackChunkName: "components/catalog-item-tech-list" */).then(c => wrapFunctional(c.default || c))
export const CatalogItemTitle = () => import('../../components/catalog/catalog-item/catalog-item-components/catalog-item-title.vue' /* webpackChunkName: "components/catalog-item-title" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
