export const state = () => ({
    backButton: {},
  menu:[
    {
      title: 'Автомобили',
      subitems:[
        {title: 'Авто с пробегом', href: '/avto-s-probegom' },
      ]
    },
    {
      title: 'Услуги',
      subitems:[
        {title: 'Автокредит', href: '/credit'},
        {title: 'Рассрочка', href: '/rassrochka'},
        {title: 'Trade-In', href: '/trejd-in'},
        {title: 'Выкуп', href: '/vykup'},
        {title: 'Бесплатный подбор', href: '/free-selection'},
      ]
    },
    {
      title: 'Информация',
      subitems:[
        {title: 'Контакты', href: '/kontakty'},
        {title: 'Об автосалоне', href: '/o-kompanii'},
        {title: 'Отзывы', href: '/reviews'},
      ]
    },
    {
      title: 'Банки',
      subitems:[
        {title: 'Банки-партнеры', href: '/banks'},
      ]
    },
    {
      title: 'Избранное',
      subitems:[
        {title: 'Избранное', href: '/favorites'},
      ]
    },
  ]
})
export const getters = {
    backButton: (state) => {
        return state.backButton
    },
  getMenu(state){
    return state.menu
  }
}

export const mutations = {
    SET_BACK_BUTTON(state, data) {
        state.backButton = data

    },
}
