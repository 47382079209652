import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9c918f1a = () => interopDefault(import('../pages/Banks.vue' /* webpackChunkName: "pages/Banks" */))
const _22233050 = () => interopDefault(import('../pages/Cookie.vue' /* webpackChunkName: "pages/Cookie" */))
const _7d491290 = () => interopDefault(import('../pages/credit/index.vue' /* webpackChunkName: "pages/credit/index" */))
const _246a1f13 = () => interopDefault(import('../pages/Favorites.vue' /* webpackChunkName: "pages/Favorites" */))
const _92850582 = () => interopDefault(import('../pages/free-selection.vue' /* webpackChunkName: "pages/free-selection" */))
const _4ea4b5a6 = () => interopDefault(import('../pages/kontakty.vue' /* webpackChunkName: "pages/kontakty" */))
const _2eff7486 = () => interopDefault(import('../pages/o-kompanii.vue' /* webpackChunkName: "pages/o-kompanii" */))
const _9c621e78 = () => interopDefault(import('../pages/Privacy.vue' /* webpackChunkName: "pages/Privacy" */))
const _5284f1de = () => interopDefault(import('../pages/rassrochka/index.vue' /* webpackChunkName: "pages/rassrochka/index" */))
const _ab658052 = () => interopDefault(import('../pages/Reviews.vue' /* webpackChunkName: "pages/Reviews" */))
const _0e8119bd = () => interopDefault(import('../pages/Thanks.vue' /* webpackChunkName: "pages/Thanks" */))
const _604bcdb6 = () => interopDefault(import('../pages/trejd-in.vue' /* webpackChunkName: "pages/trejd-in" */))
const _02251102 = () => interopDefault(import('../pages/vykup.vue' /* webpackChunkName: "pages/vykup" */))
const _79227c1c = () => interopDefault(import('../pages/credit/_bank/index.vue' /* webpackChunkName: "pages/credit/_bank/index" */))
const _739e7a2e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _46c49008 = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _dbd2c04e = () => interopDefault(import('../pages/_category/_mark/index.vue' /* webpackChunkName: "pages/_category/_mark/index" */))
const _5be37076 = () => interopDefault(import('../pages/_category/_mark/_model/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/index" */))
const _26ebc91e = () => interopDefault(import('../pages/_category/_mark/_model/_generation/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/_generation/index" */))
const _5aaa2c88 = () => interopDefault(import('../pages/_category/_mark/_model/_generation/_car/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/_generation/_car/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/Banks",
    component: _9c918f1a,
    name: "Banks"
  }, {
    path: "/Cookie",
    component: _22233050,
    name: "Cookie"
  }, {
    path: "/credit",
    component: _7d491290,
    name: "credit"
  }, {
    path: "/Favorites",
    component: _246a1f13,
    name: "Favorites"
  }, {
    path: "/free-selection",
    component: _92850582,
    name: "free-selection"
  }, {
    path: "/kontakty",
    component: _4ea4b5a6,
    name: "kontakty"
  }, {
    path: "/o-kompanii",
    component: _2eff7486,
    name: "o-kompanii"
  }, {
    path: "/Privacy",
    component: _9c621e78,
    name: "Privacy"
  }, {
    path: "/rassrochka",
    component: _5284f1de,
    name: "rassrochka"
  }, {
    path: "/Reviews",
    component: _ab658052,
    name: "Reviews"
  }, {
    path: "/Thanks",
    component: _0e8119bd,
    name: "Thanks"
  }, {
    path: "/trejd-in",
    component: _604bcdb6,
    name: "trejd-in"
  }, {
    path: "/vykup",
    component: _02251102,
    name: "vykup"
  }, {
    path: "/credit/:bank",
    component: _79227c1c,
    name: "credit-bank"
  }, {
    path: "/",
    component: _739e7a2e,
    name: "index"
  }, {
    path: "/:category",
    component: _46c49008,
    name: "category"
  }, {
    path: "/:category/:mark",
    component: _dbd2c04e,
    name: "category-mark"
  }, {
    path: "/:category/:mark/:model",
    component: _5be37076,
    name: "category-mark-model"
  }, {
    path: "/:category/:mark/:model/:generation",
    component: _26ebc91e,
    name: "category-mark-model-generation"
  }, {
    path: "/:category/:mark/:model/:generation/:car",
    component: _5aaa2c88,
    name: "category-mark-model-generation-car"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
