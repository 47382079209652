export const state = () => ({
  conditions_credit: [
    {
      number: '01',
      text: 'Первоначальный взнос от 0%',
    },
    {
      number: '02',
      text: 'Ставка по кредиту от 4.9%',
    },
    {
      number: '03',
      text: 'Срок кредита до 8 лет',
    },
    {
      number: '04',
      text: 'Размер кредита до 8 000 000 ₽',
    },
    {
      number: '05',
      text: 'Без подтверждения дохода',
    },
    {
      number: '06',
      text: 'Гражданство РФ и регистрации',
    },

  ],
  conditions_installment: [
    {
      number: '01',
      text: 'Первоначальный взнос от 0%',
    },
    {
      number: '02',
      text: 'Срок рассрочки до 3 лет',
    },
    {
      number: '03',
      text: 'Оформление по паспорту и в/у',
    },
    {
      number: '04',
      text: 'Размер рассрочки до 8 000 000 ₽',
    },
    {
      number: '05',
      text: 'Без подтверждения дохода',
    },
    {
      number: '06',
      text: 'Гражданство РФ и регистрации',
    },
  ],
})
export const getters = {
  conditions_credit(state) {
    return state.conditions_credit
  },
  conditions_installment(state) {
    return state.conditions_installment
  },
}

