export const state = () => ({
  advantages: [
    {
      id: '01',
      text: '<span>Скидка при покупке в кредит до 350 000 ₽</span>',
      description: 'Мы предоставляем дополнительную скидку до 350 000 ₽ при оформлении автокредита.',
    },
    {
      id: '02',
      text: '<span>Досрочное погашение</span>',
      description: 'Вам не придется платить дополнительные штрафы и пени при досрочном погашении кредита.',
    },
    {
      id: '03',
      text: '<span>Первоначальный взнос от 0%</span>',
      description: 'Вы можете купить автомобиль в кредит без первоначального взноса.',
    },
    {
      id: '04',
      text: '<span>Кредит по паспорту и В/У</span>',
      description: 'Чтобы оформить автомобиль в кредит Вам необходимы только паспорт и водительское удостоверение.',
    },
  ],
  advantages_main: [
    {
      id: '01',
      text: 'УЗНАТЬ ЦЕНУ',
      description: 'Оставьте заявку, чтобы получить предложение',
    },
    {
      id: '02',
      text: 'РАСЧИТАТЬ КРЕДИТ',
      description: 'Быстрое предложение и персональные условия',
    },
    {
      id: '03',
      text: 'БОЛЬШОЙ СКЛАД АВТОМОБИЛЕЙ',
      description: 'Покупка одним днем',
    },
    {
      id: '04',
      text: 'БОНУСЫ TRADE-IN',
      description: 'Обменяйте ваш а/м с доп. привелегией',
    }
  ],
  advantages_credit: [
    {
      id: '01',
      text: 'Скидка в кредит <br/><span>до 350 000₽</span>',
      description: '',
    },
    {
      id: '02',
      text: 'Досрочное погашение <br/><span>без штрафов</span>',
      description: '',
    },
    {
      id: '03',
      text: 'Двойная выгода <br/><span>по Trade-In</span>',
      description: '',
    },
    {
      id: '04',
      text: 'Оформление <br/><span>за 30 минут</span>',
      description: '',
    },
    {
      id: '05',
      text: 'Оформление по <br/><span>паспорту и в/у</span>',
      description: '',
    },
    {
      id: '06',
      text: 'Возможность оформления <br/><span>с временной регистрацией</span>',
      description: '',
    },
  ],
  advantages_tradeIn: [
    {
      id: '01',
      text: 'Выгода по Trade-In <br/><span>до 350 000₽</span>',
      description: '',
    },
    {
      id: '02',
      text: 'Оформление <br/><span>за 30 минут</span>',
      description: '',
    },
    {
      id: '03',
      text: 'Рыночная <br/><span>цена</span>',
      description: '',
    },
    {
      id: '04',
      text: 'Гарантия <br/><span>2 года</span>',
      description: '',
    },
    {
      id: '05',
      text: 'Оформление по <br/><span>паспорту и в/у</span>',
      description: '',
    },
    {
      id: '06',
      text: 'Бесплатная <br/><span>оценка</span>',
      description: '',
    },
  ],
  advantages_installment: [
    {
      id: '01',
      text: 'Процентная ставка <br/><span>не начисляется</span>',
      description: '',
    },
    {
      id: '02',
      text: 'Первый взнос <br/><span>от 0%</span>',
      description: '',
    },
    {
      id: '03',
      text: 'Оформление <br/><span>за 30 минут</span>',
      description: '',
    },
    {
      id: '04',
      text: 'Скидка в кредит <br/><span>до 350 000₽</span>',
      description: '',
    },
    {
      id: '05',
      text: 'Оформление по <br/><span>паспорту и в/у</span>',
      description: '',
    },
    {
      id: '06',
      text: 'Выгода по Trade-In <br/><span>до 300 000₽</span>',
      description: '',
    },
  ],
  advantages_buyout: [
    {
      id: '01',
      text: 'Бесплатная <br/><span>диагностика</span>',
      description: '',
    },
    {
      id: '02',
      text: 'Рыночная <br/><span>оценка авто</span>',
      description: '',
    },
    {
      id: '03',
      text: 'Выкуп авто <br/><span>любой марки</span>',
      description: '',
    },
    {
      id: '04',
      text: 'Выплата <br/><span>в день обращения</span>',
      description: '',
    },
    {
      id: '05',
      text: 'Выезд <br/><span>оценщика</span>',
      description: '',
    },
    {
      id: '06',
      text: 'Оформление <br/><span>по паспорту и в/у</span>',
      description: '',
    },
  ],
  advantages_car: [
    {
      id: '01',
      text: 'Скидка в кредит <br/><span>до 300 000₽</span>',
      description: '',
    },
    {
      id: '02',
      text: 'Досрочное погашение <br/><span>без штрафов</span>',
      description: '',
    },
    {
      id: '03',
      text: 'Двойная выгода <br/><span>по Trade-In</span>',
      description: '',
    },
    {
      id: '04',
      text: 'Первоначальный взнос <br/><span>от 0%</span>',
      description: '',
    },
    {
      id: '05',
      text: 'Оформление по <br/><span>паспорту и в/у</span>',
      description: '',
    },
    {
      id: '06',
      text: 'Возможность оформления <br/><span>с временной регистрацией</span>',
      description: '',
    },
    {
      id: '07',
      text: 'Возможность оформления <br/><span>с временной регистрацией</span>',
      description: '',
    },
    {
      id: '08',
      text: 'Возможность оформления <br/><span>с временной регистрацией</span>',
      description: '',
    },
  ],
})
export const getters = {
  advantages(state) {
    return state.advantages
  },
  advantages_credit(state) {
    return state.advantages_credit
  },
  advantages_tradeIn(state) {
    return state.advantages_tradeIn
  },
  advantages_installment(state) {
    return state.advantages_installment
  },
  advantages_buyout(state) {
    return state.advantages_buyout
  },
  advantages_car(state) {
    return state.advantages_car
  },
  advantages_main(state) {
    return state.advantages_main
  },
}

