import { render, staticRenderFns } from "./error.vue?vue&type=template&id=480fa42c&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PictureComponent: require('/home/d/didisher2/si-auto.ru/www/components/picture/picture-component.vue').default})
